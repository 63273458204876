import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompBlockCols from "../../components/MainStats/CompBlockCols"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class MessiBarcaStats extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    return (

      <Layout>
        <SEO
          title={"Lionel Messi Barcelona Goals and Stats"}
          description={`Lionel Messi goals, assists, hat-tricks, free kicks and other stats for Barcelona in La Liga, the Champions League, and all other competitions.`}
          canonicalPath={`/all-time-stats/messi-barcelona-stats/`}
        />

        <h1>
          Messi Barcelona Goals and Stats
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Inter Miami Stats', 'url': '/all-time-stats/messi-inter-miami-stats/' },
            { 'name': 'PSG Stats', 'url': '/all-time-stats/messi-psg-stats/' },
            { 'name': 'Barcelona Stats', 'url': 'none', 'active': true },
          ]}
        />

        <CompBlockCols>

          <CompetitionBlock competition="All Time Barcelona" team="Barcelona" mdata={mdata} rdata={rdata} perfmsg="[comps] only since [season]" />

          <CompetitionBlock competition="La Liga - Barcelona" compTitle="La Liga" team="Barcelona" mdata={mdata} rdata={rdata} perfmsg="Since [season]" />

          <CompetitionBlock competition="Champions League - Barcelona" compTitle="Champions League" team="Barcelona" mdata={mdata} rdata={rdata} perfmsg="Since [season]" />

          <CompetitionBlock competition="Copa del Rey - Barcelona" compTitle="Copa del Rey" team="Barcelona" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Supercopa de Espana - Barcelona" compTitle="Supercopa de Espana" team="Barcelona" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="UEFA Super Cup - Barcelona" compTitle="UEFA Super Cup" team="Barcelona" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Club World Cup - Barcelona" compTitle="Club World Cup" team="Barcelona" mdata={mdata} rdata={rdata} />

        </CompBlockCols>

        
        {/* <CopyBlock>
          <h2>Messi & Ronaldo in Games vs Each Other</h2>

          <InfoBar />

          

          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/barcelona/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/barcelona/"}}) {
      edges {
        node {
          id
          competition
        }
      }
    }
  }
`

export default MessiBarcaStats
